import styled from '@emotion/styled';
import { default_breakpoints, mq_min } from '@src/styles/breakpoints';
import {
  color_blue_20, color_darkest_grey,
  color_dark_gray,
  color_dark_grey,
  color_logo_grad_10,
  color_mid_grey
} from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import { causten_font, noTo_font } from '@src/styles/typography';

export const FeatureSection = styled.div`
  margin: ${getStyleSizes([20, 0, 40, 0])};
  h4 {
    font-size: ${getStyleSizes(28)};
    font-weight: 400;
    font-family: ${causten_font};
    line-height: ${getStyleSizes(36)};
    color: ${color_darkest_grey};
    @media (max-width: ${default_breakpoints.lg}px) {
      text-align: center;
      font-size: ${getStyleSizes(26)};
    }
  }
`;
export const FeatureList = styled.div`
  margin-top: ${getStyleSizes(30)};
`;

export const WrapperFeatureItem = styled.div`
  display: flex;
  border-top: 0.25px solid ${color_mid_grey};
  padding: ${getStyleSizes([10, 0])};
  flex-direction: column;
  align-items: center;
  &:last-child {
    border-bottom: 0.25px solid ${color_mid_grey};
  }
  ${mq_min('lg')} {
    flex-direction: row;
    align-items: left;
  }
`;

export const FeatureItem = styled.div`
  font-size: ${getStyleSizes(15)};
  font-weight: 400;
  font-family: ${noTo_font};
  width: 100%;
  text-align: center;
  color: ${color_dark_grey};
  ${mq_min('lg')} {
    width: 25%;
    padding-right: 2%;
    text-align: left;
  }
  .tooltip {
    height: 0.5rem;
    span {
      color: ${color_dark_grey};
    }
  }
  svg {
    top: -0.55em!important;
    padding-top: 0.30em;
  }
`;

export const FeatureProducts = styled.div`
  display: flex;
  width: 100%;
  max-width: ${getStyleSizes(970)};
  margin: ${getStyleSizes([15, 0, 10, 0])};
  ${mq_min('lg')} {
    margin: 0;
  }
  > div {
    &:nth-child(1) {
      > span {
        span {
          left: 0.25rem;
        }
      }
    }
  }
`;

export const FeatureContainer = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color_logo_grad_10};

  .tooltip {
    span {
      color: ${color_dark_grey};
    }
  }

  svg path {
    fill: ${color_logo_grad_10};
  }

  > span {
    display: none;
    @media (max-width: ${default_breakpoints.lg}px) {
      display: block;
    }
    &.value-text {
      color: ${color_dark_gray};
      font-family: ${noTo_font};
      font-style: normal;
      font-weight: 400;
      font-size: ${getStyleSizes(13)};
      line-height: ${getStyleSizes(22)};
      display: block;
      @media (max-width: ${default_breakpoints.lg}px) {
        display: none;
      }
    }
  }
`;

export const FeatureSeeAll = styled.div<{ show: boolean }>`
  color: ${color_blue_20};
  display: flex;
  align-items: center;
  margin-top: ${getStyleSizes(15)};
  cursor: pointer;
  font-size: ${getStyleSizes(13)};
  font-weight: 400;
  font-family: ${noTo_font};
  letter-spacing: 1%;
  justify-content: center;
  .icon {
    margin-top: ${getStyleSizes(3)};
    transform: rotateX(181deg);
  }
  ${({ show }) =>
    show &&
    `
    .icon {
      transform: rotateX(0deg)
    }
  `}
  ${mq_min('lg')} {
    justify-content: flex-start;
  }
`;
