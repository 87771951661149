import React, { useEffect, useState } from 'react';
import { ArrowIcon } from '@src/shared/components/icons';
import {
  NavigationNextButtonStyled,
  NavigationPreviousButtonStyled,
  PlanEnhancementSliderStyled,
  SliderContainerStyled,
  SliderNavigationStyled,
} from './plan-enhancement-slider.styles';
import Swiper, { Pagination } from 'swiper';
import 'swiper/css';
import { Swiper as SwiperWidget, SwiperSlide } from 'swiper/react';
import { PlanEnhancementsSliderProps } from './plan-enhancement-slider.types';
import { PlanEnhancementCard } from '../plan-enhancement-card/plan-enhacement-card';
import { useCustomSwiperWidth } from './hooks/plan-enhancement-slider.hook';
import { default_breakpoints } from '@src/styles/breakpoints';

export const PlanEnhancementSlider = ({
  widthLayout,
  cardTitleMinHeight,
  cards,
}: PlanEnhancementsSliderProps) => {
  const { swiperWidth } = useCustomSwiperWidth({ widthLayout });
  const [swiperInstance, setSwiperInstance] = useState<Swiper>();

  const onNavigationNextButton = () => {
    if (swiperInstance && !swiperInstance.isEnd) {
      swiperInstance.slideNext();
    }
  };

  const onNavigationPreviousButton = () => {
    if (swiperInstance && !swiperInstance.isBeginning) {
      swiperInstance.slidePrev();
    }
  };

  const onSwiper = (swiper: Swiper) => {
    setSwiperInstance(swiper);
  };

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.slideTo(0);
    }
  }, [cards]);

  return (
    <PlanEnhancementSliderStyled>
      {cards.length >= 4 && (
        <SliderNavigationStyled>
          <NavigationPreviousButtonStyled onClick={onNavigationPreviousButton}>
            <ArrowIcon />
          </NavigationPreviousButtonStyled>
          <NavigationNextButtonStyled onClick={onNavigationNextButton}>
            <ArrowIcon />
          </NavigationNextButtonStyled>
        </SliderNavigationStyled>
      )}

      <SliderContainerStyled sliderWidth={swiperWidth}>
        <SwiperWidget
          slidesPerView={1}
          spaceBetween={23}
          pagination={true}
          modules={[Pagination]}
          onSwiper={onSwiper}
          breakpoints={{
            [default_breakpoints.lg]: {
              spaceBetween: 44,
              slidesPerView: 'auto'
            },
          }}
        >
          {cards?.map((card, key) => (
            <SwiperSlide key={key}>
              <PlanEnhancementCard
                titleMinHeight={cardTitleMinHeight}
                {...card}
              ></PlanEnhancementCard>
            </SwiperSlide>
          ))}
        </SwiperWidget>
      </SliderContainerStyled>
    </PlanEnhancementSliderStyled>
  );
};
