import { default_breakpoints } from '@src/styles/breakpoints';
import {
  color_darkest_grey,
  color_dark_grey,
  color_lightest_grey,
  color_white,
} from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import { causten_font, noTo_font } from '@src/styles/typography';
import styled from 'styled-components';

export const PlanEnhancementCardStyled = styled.div`
  background-color: ${color_white};
  color: ${color_darkest_grey};
  padding: ${getStyleSizes([30, 16])};
  border-radius: ${getStyleSizes(4)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
`;
export const CardItemTitleStyled = styled.div<{ titleMinHeight: boolean }>`
  font-size: ${getStyleSizes(28)};
  line-height: ${getStyleSizes(36)};
  font-weight: 300;
  text-align: center;
  min-height: ${(p) => (p.titleMinHeight ? getStyleSizes(72.5) : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  .bold {
    font-weight: 600;
  }
`;

export const CardItemContentTitleStyled = styled.div`
  cursor: pointer;
`;

export const CardContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.75em;
  margin-top: 1em;
  text-align: center;
`;

export const CardPriceDescriptionStyled = styled.div`
  font-family: ${noTo_font};
  font-style: normal;
  font-size: ${getStyleSizes(20)};
  line-height: ${getStyleSizes(24)};
  letter-spacing: -0.03em;
  font-weight: 400;
  color: ${color_dark_grey};
  @media (max-width: ${default_breakpoints.lg}px) {
    font-size: ${getStyleSizes(15)};
    line-height: ${getStyleSizes(20)};
  }
`;

export const QuestionMarkContainerStyled = styled.span`
  display: inline-block;
  position: relative;
  width: ${getStyleSizes(24)};
  height: ${getStyleSizes(24)};
  cursor: pointer;
  svg {
    position: absolute;
    top: 0.35em;
    left: 0.25em;
  }
`;

export const QuestionMarkDescriptionStyled = styled.span`
  width: ${getStyleSizes(166)};
  height: fit-content;
  padding: ${getStyleSizes(12)};
  background: ${color_lightest_grey};
  box-shadow: 0px 0px ${getStyleSizes(20)} rgba(0, 0, 0, 0.25);
  border-radius: ${getStyleSizes(4)};
  font-style: normal;
  font-weight: 400;
  font-size: ${getStyleSizes(13)};
  line-height: ${getStyleSizes(16)};
  position: absolute;
  right: ${getStyleSizes(4)};
  bottom: ${getStyleSizes(32)};
  text-align: left;
  display: none;
  &.active {
    display: block;
  }
`;
