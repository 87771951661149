import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type QuestionMarkIcon = {
  accessibilitySvg?: JSX.Element;
} & SvgIconProps;
export const QuestionMarkIcon: React.FunctionComponent<QuestionMarkIcon> = (
  props
): JSX.Element => {
  return (
    <svg {...props} className="question-mark-icon">
      {props.accessibilitySvg}
      <path
        d="M6.3 11.2H7.7V9.8H6.3V11.2ZM7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6ZM7 2.8C5.453 2.8 4.2 4.053 4.2 5.6H5.6C5.6 4.83 6.23 4.2 7 4.2C7.77 4.2 8.4 4.83 8.4 5.6C8.4 7 6.3 6.825 6.3 9.1H7.7C7.7 7.525 9.8 7.35 9.8 5.6C9.8 4.053 8.547 2.8 7 2.8Z"
        fill="#A4A4A4"
      />
    </svg>
  );
};
