import React from 'react';
import { PlanDetailsProps } from './plan-details.interface';
import {
  Container,
  FeatureItem,
  ItemName,
  ItemPrice,
  PlanContactLink,
  SectionBottom,
  SectionTop,
  WrapperButton,
} from './plan-details.styles';

import MailIcon from '@mui/icons-material/MailSharp';
import PrimaryButton from '../../primary-button';
import { processTextForBoldStyle } from '@src/shared/utilities/string.utils';

export const PlanDetails = ({
  contact_email,
  product_details,
}: PlanDetailsProps) => {
  return (
    <Container>
      <SectionTop>
        <h2>Plan Details</h2>
        <PlanContactLink href={`mailto:${contact_email}`}>
          <MailIcon sx={{ fontSize: 19 }} />
          <span>Email</span>
        </PlanContactLink>
      </SectionTop>
      <SectionBottom>
        {product_details?.map((product, key) => (
          <FeatureItem
            width={Math.round(100 / product_details.length)}
            key={key}
          >
            <ItemName
              dangerouslySetInnerHTML={{
                __html: processTextForBoldStyle(product.product_name),
              }}
            />
            <ItemPrice>
              <b>${product.price}</b>
              <span>
                <p>{product.per_line_1}</p>
                <p>{product.per_line_2}</p>
              </span>
            </ItemPrice>
            <WrapperButton>
              <PrimaryButton
                color="miami"
                ariaLabel="Contact Us"
                path={product.button?.link_url}
                label={product.button?.link_text}
                size="medium"
                variant="contained"
                icon="text"
              />
            </WrapperButton>
          </FeatureItem>
        ))}
      </SectionBottom>
      {product_details && !!product_details.length && (
        <WrapperButton>
          <PrimaryButton
            color="miami"
            ariaLabel="Contact Us"
            path={product_details[0]?.button.link_url}
            label={'Contact us to get started'}
            size="medium"
            variant="contained"
            icon="text"
          />
        </WrapperButton>
      )}
    </Container>
  );
};
