import React from 'react';
import { CheckIcon } from '../../icons/check-icon/check-icon';
import { FeaturesSectionProps } from './features-section.interface';
import {
  FeatureContainer,
  FeatureItem,
  FeatureList,
  FeatureProducts,
  FeatureSection,
  FeatureSeeAll,
  WrapperFeatureItem,
} from './features-section.styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { QuestionMarkIcon } from '../../icons/question-mark-icon/question-mark-icon';
import { Tooltip } from '../../tooltip/tooltip';
import { useToggleFeature } from './hooks/useToggleFeature';
import { CSFeatureList, ProductIndex } from '@src/shared/contentstack';
import {
  processSimpleTextToBaseIdFormat,
  processTextForCleanStyle,
} from '@src/shared/utilities/string.utils';

const MAX = 4;

export const FeaturesSection = ({
  productDetails,
  features_section,
  lengthPlans,
}: FeaturesSectionProps) => {
  const { handlerToogle, data } = useToggleFeature(features_section);
  const keyPlan = (key: number) =>
    ('product_' + (key + 1) + '_value') as ProductIndex;
  return (
    <div>
      {data?.map(({ close, feature_section_title, feature_list }, key1) => (
        <FeatureSection
          id={`plan-details-${processSimpleTextToBaseIdFormat(feature_section_title)}`}
          key={key1}
        >
          <h4>{feature_section_title}</h4>
          <FeatureList>
            {feature_list
              .slice(0, close ? MAX : feature_list.length)
              .map((feature: CSFeatureList, key2) => (
                <WrapperFeatureItem key={key2}>
                  <FeatureItem>
                    <span
                      aria-describedby={`${key1}_${key2}_tooltip_desc`}
                      role="option"
                      tabIndex={0}
                    >
                      {feature.feature_name}
                    </span>
                    {feature.feature_help_text && (
                      <Tooltip
                        id={`${key1}_${key2}_tooltip_desc`}
                        description={feature.feature_help_text}
                        icon={
                          <QuestionMarkIcon
                            width={24}
                            height={24}
                            role={'img'}
                            accessibilitySvg={
                              <>
                                <title id={`${key1}_${key2}_tooltip`}>
                                  {feature.feature_help_text}
                                </title>
                              </>
                            }
                          />
                        }
                      />
                    )}
                  </FeatureItem>
                  <FeatureProducts>
                    {[...Array(lengthPlans).keys()]?.map((key) => {
                      const keyProduct = keyPlan(key);
                      return (
                        <FeatureContainer
                          width={Math.round(100 / lengthPlans)}
                          key={key}
                        >
                          {feature[keyProduct]?.display_checkmark ? (
                            <CheckIcon
                              accessibilitySvg={
                                <>
                                  <title
                                    id={`${key1}_${key2}_${key}_${keyProduct}_title`}
                                  >
                                    {feature.feature_name}{' '}
                                    {`Available for ${processTextForCleanStyle(
                                      productDetails[key]?.product_name
                                    )}`}{' '}
                                  </title>
                                </>
                              }
                              width={24}
                              height={24}
                              role={'img'}
                              fontSize="small"
                            />
                          ) : (
                            <>
                              {feature[keyProduct]?.value_text && (
                                <Tooltip
                                  description={feature[keyProduct]?.value_text}
                                  icon={
                                    <QuestionMarkIcon
                                      width={24}
                                      height={24}
                                      role={'img'}
                                      accessibilitySvg={
                                        <>
                                          <title id={`${key1}_${key2}_tooltip`}>
                                            {feature.feature_help_text}
                                          </title>
                                        </>
                                      }
                                    />
                                  }
                                />
                              )}
                              <span className="value-text">
                                {feature[keyProduct]?.value_text}
                              </span>
                            </>
                          )}
                        </FeatureContainer>
                      );
                    })}
                  </FeatureProducts>
                </WrapperFeatureItem>
              ))}
          </FeatureList>
          {feature_list.length > MAX && (
            <FeatureSeeAll
              show={close}
              onClick={() => handlerToogle(!close, key1)}
            >
              See {close ? 'All' : 'Less'} {feature_section_title}
              <ExpandMoreIcon className="icon" fontSize="small" />
            </FeatureSeeAll>
          )}
        </FeatureSection>
      ))}
    </div>
  );
};
