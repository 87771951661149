import styled from '@emotion/styled';

import { getStyleSizes } from '@styles/styles.utils';
import { color_darkest_grey } from '@styles/colors';
import { causten_font } from '@styles/typography';
import { mq_min } from '@src/styles/breakpoints';

export const SectionTop = styled.div`
  text-align: center;
  padding: ${getStyleSizes([40, 0, 30, 0])};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([40, 0])};
    width: 15%;
    text-align: left;
    margin-right: 10%;
  }

  h2 {
    ${mq_min('lg')} {
      font-size: ${getStyleSizes(50)};
      line-height: ${getStyleSizes(48)};
    }

    font-size: ${getStyleSizes(34)};
    line-height: ${getStyleSizes(36)};

    font-weight: 400;
    font-family: ${causten_font};
  }
`;

export const SectionBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${mq_min('lg')} {
    padding: ${getStyleSizes([54, 0])};
  }
`;

export const PlanContactLink = styled.a`
  display: inline-flex;
  gap: 5px;
  font-size: ${getStyleSizes(13)};
  margin-top: ${getStyleSizes(15)};
  color: ${color_darkest_grey};
  justify-content: center;

  ${mq_min('lg')} {
    justify-content: left;
    margin-top: ${getStyleSizes(25)};
  }
`;

export const Container = styled.div`
  ${mq_min('lg')} {
    display: flex;
  }
`;

export const WrapperButton = styled.div`
  text-align: center;
  margin-top: 20px;
  ${mq_min('lg')} {
    margin-top: 0;
    display: none;
  }
  button {
    width: 100%;
  }
`;

export const FeatureItem = styled.div<{ width: number }>`
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}%`};

  ${WrapperButton} {
    display: none;
    ${mq_min('lg')} {
      display: block;
      button {
        width: auto;
      }
    }
  }
`;

export const ItemName = styled.div`
  font-weight: 200;
  font-family: ${causten_font};
  text-align: center;
  font-size: ${getStyleSizes(16)};
  line-height: ${getStyleSizes(22)};
  display: flex;
  flex-direction: column;
  ${mq_min('sm')} {
    font-size: ${getStyleSizes(24)};
  }
  ${mq_min('lg')} {
    font-size: ${getStyleSizes(28)};
    line-height: ${getStyleSizes(36)};
    flex-direction: row;
    gap: 7px;
    display: flex;
    justify-content: center;
  }

  .bold {
    font-weight: bold;
  }
`;


export const ItemPrice = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  ${mq_min('lg')} {
    flex-direction: row;
    text-align: left;
    gap: 10px;
  }
  b {
    display: flex;
    align-items: center;
    font-size: ${getStyleSizes(28)};
    font-weight: 600;
    font-family: ${causten_font};
    line-height: ${getStyleSizes(36)};
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  p {
    font-size: ${getStyleSizes(13)};
    line-height: ${getStyleSizes(12)};
    font-weight: 400;
    font-family: ${causten_font};

    ${mq_min('lg')} {
      font-size: ${getStyleSizes(12)};
      line-height: ${getStyleSizes(12)};
    }
  }
`;
