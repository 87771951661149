import styled from '@emotion/styled';

import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';

import {
  color_blue_10,
  color_blue_20,
  color_bright_blue,
  color_darkest_grey,
  color_dark_grey,
  color_gray_10,
  color_gray_100,
  color_gray_40,
  color_light_grey,
  color_logo_grad_10,
  color_text_grey,
  color_white,
} from '@styles/colors';

import { causten_font, noTo_font } from '@styles/typography';
import { BackgroundProp, BackgroundType } from './product-card.interface';

export const themes = {
  White: {
    containerBackground: color_white,
    containerBorderColor: color_light_grey,
    teaserColor: color_dark_grey,
    headlineColor: color_darkest_grey,
    descriptionColor: color_darkest_grey,
    bulletsColor: color_darkest_grey,
  },
  'Light Gray': {
    containerBackground: color_gray_10,
    containerBorderColor: color_gray_10,
    teaserColor: color_dark_grey,
    headlineColor: color_darkest_grey,
    descriptionColor: color_darkest_grey,
    bulletsColor: color_darkest_grey,
  },
  'Dark Gray': {
    containerBackground: color_gray_40,
    containerBorderColor: color_gray_40,
    teaserColor: color_gray_100,
    headlineColor: color_darkest_grey,
    descriptionColor: color_gray_100,
    bulletsColor: color_darkest_grey,
  },
  Black: {
    containerBackground: color_darkest_grey,
    containerBorderColor: color_darkest_grey,
    teaserColor: color_text_grey,
    headlineColor: color_bright_blue,
    descriptionColor: color_text_grey,
    bulletsColor: color_logo_grad_10,
  },
};

export const ProductName = styled.div<BackgroundProp>`
  font-size: ${getStyleSizes(36)};
  font-weight: 400;
  font-family: ${causten_font};
  line-height: ${getStyleSizes(36)};
  color: ${({ backgroundColor }) => themes[backgroundColor]?.headlineColor};
  text-align: center;

  .bold {
    font-weight: bold;
  }
`;

export const Teaser = styled.p<BackgroundProp>`
  padding: ${getStyleSizes([15, 25, 0, 25])};
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(15)};
  line-height: ${getStyleSizes(26)};
  font-weight: 350;
  letter-spacing: 0.01em;
  text-align: center;
  color: ${({ backgroundColor }) => themes[backgroundColor]?.teaserColor};
`;

export const Container = styled.div<BackgroundProp>`
  display: flex;
  align-items: center;
  min-height: ${getStyleSizes(595)};
  border: 1px solid
    ${({ backgroundColor }) => themes[backgroundColor]?.containerBorderColor};
  background: ${({ backgroundColor }) =>
    themes[backgroundColor]?.containerBackground};
  border-radius: 8px;
  flex-direction: column;
  padding: ${getStyleSizes([45, 0, 40, 0])};
  color: ${({ backgroundColor }) => themes[backgroundColor]?.descriptionColor};
  position: relative;
  margin: ${getStyleSizes(20)};

  ${mq_min('lg')} {
    margin: ${getStyleSizes([20, 10])};
  }
`;

export const PriceContent = styled.div`
  display: flex;
  margin-top: ${getStyleSizes(10)};
  gap: ${getStyleSizes(12)};
`;

export const Tag = styled.div`
  font-size: ${getStyleSizes(16)};
  font-weight: 600;
  font-family: ${causten_font};
  line-height: ${getStyleSizes(19)};
  margin-top: ${getStyleSizes(15)};
`;

export const Price = styled.p`
  font-size: ${getStyleSizes(36)};
  font-weight: 600;
  font-family: ${causten_font};
  line-height: ${getStyleSizes(36)};
`;

export const Lines = styled.div`
  p {
    font-size: ${getStyleSizes(13)};
    font-weight: 400;
    font-family: ${causten_font};
    line-height: ${getStyleSizes(16)};
  }
`;

export const WrapperButton = styled.div`
  margin: ${getStyleSizes([15, 0, 30, 0])};
`;

const IconSvg = (color: string) =>
  `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='13' fill='none'%3E%3Cpath stroke='${encodeURIComponent(
    color
  )}' stroke-width='3' d='M2 5.8 6.131 10 14 2'/%3E%3C/svg%3E`;

const urlBackground = (backgroundColor: BackgroundType) =>
  IconSvg(themes[backgroundColor]?.bulletsColor);

// prettier-ignore
export const ContentRichText = styled.span<BackgroundProp>`
  ul {
    li {
      padding-left: ${getStyleSizes(10)};
      list-style-image: url("${({ backgroundColor }) => urlBackground(backgroundColor)}");
    }
  }
  p {
    margin: ${getStyleSizes([0, 40, 0, 50])};
    font-size: ${getStyleSizes(13)};
    font-weight: 400;
    font-family: ${causten_font};
    line-height: ${getStyleSizes(20)};
  }
`;

export const BubbleTag = styled.div`
  width: ${getStyleSizes(53)};
  height: ${getStyleSizes(73)};
  background: ${color_blue_20};
  border-radius: 50%;
  font-weight: 600;
  display: flex;
  font-size: ${getStyleSizes(16)};
  font-family: ${causten_font};
  line-height: ${getStyleSizes(18)};
  padding: ${getStyleSizes([0, 10])};
  text-align: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: -20px;
  right: -11px;
  color: ${color_white};
`;
