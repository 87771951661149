import styled from '@emotion/styled';
import { color_bright_blue, color_mid_grey } from '@src/styles/colors';

export const Container = styled.div`
  display: flex;

  .swiper-pagination {
    bottom: -7px;

    .swiper-pagination-bullet {
      background: ${color_mid_grey};
      &.swiper-pagination-bullet-active {
        background: ${color_bright_blue};
      }
    }
  }
`;
