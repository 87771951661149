import styled from '@emotion/styled';
import { mq_max, mq_min } from '@src/styles/breakpoints';
import { color_black, color_blue_10, color_dark_grey } from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import { causten_font, noTo_font } from '@src/styles/typography';

export const Container = styled.div`
  gap: ${getStyleSizes(40)};
  height: ${getStyleSizes(100)};
  margin: ${getStyleSizes([20,0])};
  ${mq_min('lg')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContactHeading = styled.h4`
  font-family: ${causten_font};
  font-style: normal;
  font-weight: 600;
  font-size: ${getStyleSizes(22)};
  line-height: ${getStyleSizes(32)};
  text-align: center;
  letter-spacing: -0.03em;
  color: ${color_blue_10};
  margin-bottom: ${getStyleSizes(5)};
  ${mq_min('lg')} {
    font-size: ${getStyleSizes(28)};
  }
`;
export const ContentRichText = styled.div`
  > div {
    ${mq_min('lg')} {
      display: flex;
      gap: 5px;
    }
  }
  p {
    font-family: ${noTo_font};
    font-weight: 400;
    font-size: ${getStyleSizes(14)};
    letter-spacing: 0.01em;
    color: ${color_dark_grey};
    text-align: center;
    p {
      display: inline-flex;
    }
    ${mq_min('lg')} {
      font-size: ${getStyleSizes(15)};
    }
  }
  a {
    color: ${color_black};
  }
`;
