import { default_breakpoints } from '@src/styles/breakpoints';
import { color_bright_blue, color_gray, color_white } from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import styled from 'styled-components';

export const PlanEnhancementSliderStyled = styled.div`
  display: flex;
  position: relative;
`;
export const NavigationPreviousButtonStyled = styled.div`
  svg {
    transform: scaleX(-1);
  }
`;
export const NavigationNextButtonStyled = styled.div``;

export const SliderNavigationStyled = styled.div`
  position: absolute;
  right: 0;
  top: -3.5em;
  display: flex;
  flex-direction: row;
  gap: ${getStyleSizes(11)};
  align-items: flex-end;
  padding-bottom: ${getStyleSizes(24)};
  ${NavigationPreviousButtonStyled}, ${NavigationNextButtonStyled} {
    border-radius: 50%;
    width: ${getStyleSizes(34)};
    height: ${getStyleSizes(34)};
    border: 1px solid ${color_white};
    color: ${color_white};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: ${getStyleSizes(20)};
    }
  }
  @media (max-width: ${default_breakpoints.lg}px) {
    display: none;
  }
`;

export const SliderContainerStyled = styled.div<{ sliderWidth: number }>`
  display: flex;
  flex-direction: row;
  .swiper {
    width: ${(p) => getStyleSizes(p.sliderWidth)};
    padding-right: ${getStyleSizes(44)};
    overflow: inherit;
    .swiper-wrapper {
      .swiper-slide {
        width: ${getStyleSizes(314)};
      }
    }
    .swiper-pagination {
      display: none;
      @media (max-width: ${default_breakpoints.lg}px) {
        margin-left: ${getStyleSizes(-15)};
        display: block;
        bottom: -2.55em;
        .swiper-pagination-bullet {
          background-color: ${color_gray} !important;
        }
        .swiper-pagination-bullet {
          background-color: ${color_bright_blue} !important;
        }
      }
    }
  }
`;
