import React, { useEffect, useState } from 'react';

import PrimaryButton from '../../primary-button';
import { PlanEnhancementsProps } from './plan-enhancements.types';
import {
  ButtonContainerStyled,
  ContainerHeaderStyled,
  HeaderLeftContainerStyled,
  HeaderRightContainerStyled,
  PlanEnhancementsContentStyled,
  PlanEnhancementsHeaderStyled,
  PlanEnhancementsLayoutStyled,
  PlanEnhancementsStyled,
  TabItemStyled,
  TabListStyled,
  TabsStyled,
} from './plan-enhancements.styles';
import { PlanEnhancementSlider } from './plan-enhancement-slider/plan-enhancement-slider';
import { useCardTitleMinHeight } from './hooks/card-title-min-height.hook';

export const PlanEnhancements = ({
  widthLayout,
  button,
  panel_title,
  card_tab,
}: PlanEnhancementsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const { cardTitleMinHeight } = useCardTitleMinHeight({
    cardTab: card_tab,
    selectedTabIndex,
  });

  const setupSelectedTabIndex = (tabIndex: number, e: any) => {
    setSelectedTabIndex(tabIndex);
  };
  return (
    <PlanEnhancementsStyled id="pricing-page-plan-enhancements">
      <PlanEnhancementsLayoutStyled width={widthLayout}>
        <PlanEnhancementsHeaderStyled>
          <HeaderLeftContainerStyled>{panel_title}</HeaderLeftContainerStyled>
          <HeaderRightContainerStyled>
            <PrimaryButton
              color="miami"
              ariaLabel="Contact Us"
              path={button?.link_url}
              label={button?.link_text}
              size={'large'}
              variant={'contained'}
              icon={'text'}
            />
          </HeaderRightContainerStyled>
        </PlanEnhancementsHeaderStyled>
        <PlanEnhancementsContentStyled>
          <ContainerHeaderStyled>
            <TabsStyled>
              <TabListStyled>
                {card_tab?.map((tab, index) => (
                  <TabItemStyled
                    key={index}
                    className={selectedTabIndex === index ? 'active' : ''}
                    onClick={setupSelectedTabIndex.bind(this, index)}
                  >
                    <span>{tab.tab_title}</span>
                  </TabItemStyled>
                ))}
              </TabListStyled>
            </TabsStyled>

            <PlanEnhancementSlider
              cardTitleMinHeight={cardTitleMinHeight}
              cards={card_tab[selectedTabIndex]?.cards}
              widthLayout={widthLayout}
            ></PlanEnhancementSlider>
          </ContainerHeaderStyled>
        </PlanEnhancementsContentStyled>
        <ButtonContainerStyled>
          <PrimaryButton
            color="miami"
            ariaLabel="Contact Us"
            path={button?.link_url}
            label={button?.link_text}
            size={'large'}
            variant={'contained'}
            icon={'text'}
          />
        </ButtonContainerStyled>
      </PlanEnhancementsLayoutStyled>
    </PlanEnhancementsStyled>
  );
};
