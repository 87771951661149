import styled from '@emotion/styled';
import { mq_min } from '@src/styles/breakpoints';
import { color_darkest_grey, color_dark_grey } from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';

export const PricingPageHeading = styled.div`
  margin: ${getStyleSizes([25, 0, 0, 0])};
  ${mq_min('lg')} {
    margin: ${getStyleSizes([50, 0, 0, 0])};
  }
  .banner-simple {
    padding: ${getStyleSizes([10, 0])};
    ${mq_min('lg')} {
      padding: ${getStyleSizes([40, 10])};
    }
  }

  h1 {
    font-size: ${getStyleSizes(34)};
    ${mq_min('lg')} {
      font-size: ${getStyleSizes(50)};
    }
  }
  p {
    font-size: ${getStyleSizes(16)};
    color: ${color_dark_grey};
    ${mq_min('lg')} {
      font-size: ${getStyleSizes(18)};
    }
  }
`;

export const PricingPageQuote = styled.div`
  > div {
    padding: 0
  }
`
