import { useEffect, useState } from 'react';
import { PlanEnhancementSliderWidthHookProps } from '../plan-enhancement-slider.types';

export const useCustomSwiperWidth = ({
  widthLayout,
}: PlanEnhancementSliderWidthHookProps) => {
  const [swiperWidth, setSwiperWidth] = useState<number>(widthLayout);
  useEffect(() => {
    if (document.documentElement.clientWidth > widthLayout) {
      let ScreenExtraPadding =
        document.documentElement.clientWidth - widthLayout;
      setSwiperWidth(widthLayout + ScreenExtraPadding / 2);
    } else {
      setSwiperWidth(document.documentElement.clientWidth - 30);
    }
  }, []);
  return {
    swiperWidth,
  };
};
