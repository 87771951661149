import { processTextForBoldStyle } from '@src/shared/utilities/string.utils';
import React from 'react';
import PrimaryButton from '../../primary-button';
import { RichText } from '../../rich-text';
import { ProductCardProps } from './product-card.interface';
import {
  BubbleTag,
  Container,
  ContentRichText,
  Lines,
  Price,
  PriceContent,
  ProductName,
  Tag,
  Teaser,
  WrapperButton,
} from './product-card.styles';

export const ProductCard = ({
  product_name,
  teaser,
  pricing_tagline,
  price,
  per_line_1,
  per_line_2,
  bullets,
  button,
  background_color,
  highlight_bubble,
  highlight_bubble_text,
}: ProductCardProps) => {
  return (
    <Container
      backgroundColor={background_color}
      data-testid="product-card__container"
      id={bullets.uid}
    >
      {highlight_bubble && <BubbleTag>{highlight_bubble_text}</BubbleTag>}
      {product_name && (
        <ProductName
          backgroundColor={background_color}
          dangerouslySetInnerHTML={{
            __html: processTextForBoldStyle(product_name),
          }}
        />
      )}
      {teaser && <Teaser backgroundColor={background_color}>{teaser}</Teaser>}
      {pricing_tagline && <Tag>{pricing_tagline}</Tag>}
      {price && (
        <PriceContent>
          <Price>${price}</Price>
          <Lines>
            <p>{per_line_1}</p>
            <p>{per_line_2}</p>
          </Lines>
        </PriceContent>
      )}
      {button && (
        <WrapperButton>
          <PrimaryButton
            color="miami"
            ariaLabel="Contact Us"
            path={button?.link_url}
            label={button?.link_text}
            size="large"
            variant="contained"
            icon="text"
          />
        </WrapperButton>
      )}
      {bullets && (
        <ContentRichText backgroundColor={background_color}>
          <RichText content={bullets} />
        </ContentRichText>
      )}
    </Container>
  );
};
