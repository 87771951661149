import { CSFeaturesSection } from '@src/shared/contentstack';
import { useState } from 'react';

const toggleCloseAll = (features_section: CSFeaturesSection[]) =>
  features_section?.map((feature) => ({
    ...feature,
    close: true,
  }));

export const useToggleFeature = (features_section: CSFeaturesSection[]) => {
  const [data, setData] = useState(toggleCloseAll(features_section));

  const handlerToogle = (close: boolean, index: number) => {
    setData(
      data?.map((feature, key) =>
        key === index
          ? {
              ...feature,
              close,
            }
          : feature
      )
    );
  };

  return { handlerToogle, data };
};
