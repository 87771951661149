import React, { useState } from 'react';
import { TooltipContainerStyled, TooltipStyled } from './tooltip.styles';
import { TooltipProps } from './tooltip.types';

export const Tooltip = ({ id, description, icon }: TooltipProps) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const questionMarkToggle = (status: boolean, e: any) => {
    setEnableTooltip(status);
  };
  return (
    <TooltipStyled
      onMouseOver={questionMarkToggle.bind(this, true)}
      onMouseLeave={questionMarkToggle.bind(this, false)}
      className={'tooltip'}
      tabIndex={0}
    >
      {icon}
      <TooltipContainerStyled
        role={'tooltip'}
        id={id}
        className={enableTooltip ? 'active' : ''}
      >
        {description}
      </TooltipContainerStyled>
    </TooltipStyled>
  );
};
