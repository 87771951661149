import { QuestionMarkIcon } from '@src/shared/components/icons/question-mark-icon/question-mark-icon';
import { processTextForBoldStyle } from '@src/shared/utilities/string.utils';
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import {
  CardContentStyled,
  CardItemContentTitleStyled,
  CardItemTitleStyled,
  CardPriceDescriptionStyled,
  PlanEnhancementCardStyled,
  QuestionMarkContainerStyled,
  QuestionMarkDescriptionStyled,
} from './plan-enhacement-card.styles';
import { PlanEnhancementCardProps } from './plan-enhacement-card.types';

export const PlanEnhancementCard = ({
  enhancement_name,
  optional_help_text,
  cost_description,
  titleMinHeight,
}: PlanEnhancementCardProps) => {
  const [enableQuestionMarkDescription, setEnableQuestionMarkDescription] =
    useState(false);

  const questionMarkToggle = (status: boolean, e: any) => {
    setEnableQuestionMarkDescription(status);
  };

  const setupCardTitle = () => {
    return optional_help_text
      ? processTextForBoldStyle(enhancement_name) +
          renderToString(
            <QuestionMarkContainerStyled>
              <QuestionMarkIcon />
              <QuestionMarkDescriptionStyled
                className={enableQuestionMarkDescription ? 'active' : ''}
              >
                {optional_help_text}
              </QuestionMarkDescriptionStyled>
            </QuestionMarkContainerStyled>
          )
      : processTextForBoldStyle(enhancement_name);
  };

  useEffect(() => {
    setEnableQuestionMarkDescription(false);
  }, [titleMinHeight]);
  return (
    <PlanEnhancementCardStyled>
      <CardItemTitleStyled titleMinHeight={titleMinHeight}>
        <CardItemContentTitleStyled
          onMouseOver={questionMarkToggle.bind(this, true)}
          onMouseLeave={questionMarkToggle.bind(this, false)}
          dangerouslySetInnerHTML={{
            __html: setupCardTitle(),
          }}
        ></CardItemContentTitleStyled>
      </CardItemTitleStyled>
      {cost_description && (
        <CardContentStyled>
          <CardPriceDescriptionStyled>
            {cost_description}
          </CardPriceDescriptionStyled>
        </CardContentStyled>
      )}
    </PlanEnhancementCardStyled>
  );
};
