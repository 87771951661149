import { color_darkest_grey, color_lightest_grey } from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import styled from 'styled-components';

export const TooltipStyled = styled.span`
  display: inline-block;
  position: relative;
  width: ${getStyleSizes(24)};
  height: ${getStyleSizes(24)};
  cursor: pointer;
  svg {
    position: absolute;
    top: 0.3em;
    left: 0.175em;
  }
`;

export const TooltipContainerStyled = styled.span`
  color: ${color_darkest_grey};
  width: ${getStyleSizes(166)};
  height: fit-content;
  padding: ${getStyleSizes(12)};
  background: ${color_lightest_grey};
  box-shadow: 0px 0px ${getStyleSizes(20)} rgba(0, 0, 0, 0.25);
  border-radius: ${getStyleSizes(4)};
  font-style: normal;
  font-weight: 400;
  font-size: ${getStyleSizes(13)};
  line-height: ${getStyleSizes(16)};
  position: absolute;
  right: ${getStyleSizes(4)};
  bottom: ${getStyleSizes(32)};
  text-align: left;
  display: none;
  &.active {
    display: block;
  }
`;
