import { useEffect, useState } from 'react';
import { CardTitleMinHeightHookProps } from '../plan-enhancements.types';

export const useCardTitleMinHeight = ({
  cardTab,
  selectedTabIndex,
}: CardTitleMinHeightHookProps) => {
  const [cardTitleMinHeight, setCardTitleMinHeight] = useState<boolean>(true);
  useEffect(() => {
    setCardTitleMinHeight(true);
    cardTab[selectedTabIndex]?.cards?.map((card) => {
      let checkSymbol = 0;
      if (card.enhancement_name.indexOf('<<') > -1) {
        checkSymbol = 4;
      }
      if (card.enhancement_name.length - checkSymbol <= 16) {
        setCardTitleMinHeight(false);
      }
    });
  }, [selectedTabIndex]);
  return {
    cardTitleMinHeight,
  };
};
