import { CSPricingPage } from '@shared/contentstack';
import { QuotePromo } from '@src/cms-components';
import { BannerSimple } from '@src/cms-components/hero-banner/components';
import { quotePromoMapper } from '@src/cms-components/promos/quote-promo/mappers/quote-promo.mapper';
import { default_breakpoints } from '@src/styles/breakpoints';
import React from 'react';
import CMSComponentWrapper from '../component-wrapper';
import { Contact } from './contact/contact';
import { color_lightest_grey } from '@src/styles/colors';
import { PlanEnhancements } from './plan-enhancements/plan-enhancements';
import { PricingPageHeading, PricingPageQuote } from './pricing-page.styles';
import { ProductDetails } from './product-details/product-details';
import { PlanDetails } from './plan-details';
import { FeaturesSection } from './features-section/features-section';
import {
  filterDefinedComponents,
  mappedComponent,
} from '@src/shared/utilities/page.util';

export const PricingPage = ({ pageContext }: CSPricingPage) => {
  const {
    banner_copy,
    headline,
    product_details,
    contact_heading,
    contact_details,
    boxed_promo,
    plan_enhancements,
    features_section,
    contact_email,
    components,
  } = pageContext || {};
  return (
    <>
      <CMSComponentWrapper maxWidth={1240} uid="pricing-page-product-details">
        <PricingPageHeading>
          <BannerSimple
            headline={headline}
            banner_copy={banner_copy}
            make_h1_headline={true}
            banner_background_color="light"
          />
        </PricingPageHeading>
        <ProductDetails product_details={product_details} />
        <Contact heading={contact_heading} details={contact_details} />
        <PricingPageQuote>
          <QuotePromo
            {...quotePromoMapper(boxed_promo[0], {
              backgroundColor: 'light',
              variant: 'boxed',
            })}
          />
        </PricingPageQuote>
      </CMSComponentWrapper>
      <CMSComponentWrapper
        backgroundColor={color_lightest_grey}
        maxWidth={1240}
        margin={[50, 0]}
        uid="pricing-page-plan-details"
      >
        <PlanDetails
          contact_email={contact_email}
          product_details={product_details}
        />
      </CMSComponentWrapper>
      <CMSComponentWrapper
        uid="pricing-page-features"
        maxWidth={1240}
        margin={[50, 0]}
      >
        <FeaturesSection
          productDetails={product_details}
          features_section={features_section}
          lengthPlans={product_details?.length}
        />
      </CMSComponentWrapper>
      <PlanEnhancements
        widthLayout={default_breakpoints.cw}
        {...plan_enhancements}
      ></PlanEnhancements>
      {filterDefinedComponents(components).map((comp: any, index: number) =>
        mappedComponent(comp.type, comp, index)
      )}
    </>
  );
};
