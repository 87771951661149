import { default_breakpoints } from '@src/styles/breakpoints';
import {
  color_darkest_grey,
  color_light_gray,
  color_light_grey,
  color_logo_grad_10,
  color_white,
} from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import { causten_font } from '@src/styles/typography';
import styled from 'styled-components';

export const PlanEnhancementsStyled = styled.div`
  background-color: ${color_darkest_grey};
  margin-top: ${getStyleSizes(96)};
  overflow-x: hidden;
  min-height: ${getStyleSizes(484)};
  @media (max-width: ${default_breakpoints.lg}px) {
    background-color: ${color_light_gray};
  }
`;

export const PlanEnhancementsLayoutStyled = styled.div<{ width: number }>`
  max-width: ${(p) => p.width}px;
  margin: 0 auto;
  padding: ${getStyleSizes([96, 0])};
  @media (max-width: ${default_breakpoints.cw}px) {
    padding: ${getStyleSizes([96, 30])};
  }
  @media (max-width: ${default_breakpoints.lg}px) {
    padding: ${getStyleSizes([40, 30, 47])};
  }
`;
export const PlanEnhancementsHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const HeaderLeftContainerStyled = styled.div`
  font-size: ${getStyleSizes(50)};
  font-weight: 400;
  font-family: ${causten_font};
  line-height: ${getStyleSizes(36)};
  color: ${color_white};
  @media (max-width: ${default_breakpoints.lg}px) {
    font-size: ${getStyleSizes(34)};
  }
`;
export const HeaderRightContainerStyled = styled.div`
  @media (max-width: ${default_breakpoints.lg}px) {
    display: none;
  }
`;
export const PlanEnhancementsContentStyled = styled.div``;
export const ContainerHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`;
export const TabsStyled = styled.div`
  padding: ${getStyleSizes([50, 0, 29])};
  @media (max-width: ${default_breakpoints.lg}px) {
    padding: ${getStyleSizes([24, 0, 29])};
  }
`;
export const TabListStyled = styled.div`
  display: flex;
  flex-direction: row;
  color: ${color_light_grey};
`;
export const TabItemStyled = styled.div`
  margin-right: ${getStyleSizes(46)};
  text-transform: uppercase;
  font-size: ${getStyleSizes(12)};
  line-height: ${getStyleSizes(14.4)};
  font-family: ${causten_font};
  font-weight: 400;
  cursor: pointer;
  padding-bottom: ${getStyleSizes(4)};
  padding-top: ${getStyleSizes(1)};
  &.active {
    font-weight: 600;
    color: ${color_white};
    border-bottom: ${getStyleSizes(2)} solid ${color_logo_grad_10};
    padding-top: ${getStyleSizes(0)};
  }
`;

export const ButtonContainerStyled = styled.div`
  display: none;
  @media (max-width: ${default_breakpoints.lg}px) {
    display: block;
    margin-top: ${getStyleSizes(60)};
  }
`;
