import React from 'react';
import { RichText } from '../../rich-text';
import { ContactProps } from './contact.interface';
import { ContactHeading, Container, ContentRichText } from './contact.styles';

export const Contact = ({ heading, details }: ContactProps) => {
  return (
    <Container id={details.uid} data-testid="contact__container">
      {heading && <ContactHeading>{heading}</ContactHeading>}
      {details && (
        <ContentRichText>
          <RichText content={details} />
        </ContentRichText>
      )}
    </Container>
  );
};
