import { default_breakpoints } from '@src/styles/breakpoints';
import React from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProductCard } from '../product-card';
import { ProductDetailsProps } from './product-details.interface';
import { Container } from './product-details.styles';

export const ProductDetails = ({ product_details }: ProductDetailsProps) => {
  const totalProductDetails = product_details.length || 0;

  return (
    <Container>
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          [default_breakpoints.md]: {
            slidesPerView: 2,
          },
          [default_breakpoints.lg]: {
            slidesPerView: totalProductDetails,
          },
        }}
      >
        {product_details?.map((product, key) => (
          <SwiperSlide key={key}>
            <div className="bubble"></div>
            <ProductCard key={key} {...product} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
